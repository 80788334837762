<template>
  <v-row class="ClassImagen text-center" >
    <v-col cols="12" >
      <v-img
        contain
        max-height="650"
        class="mx-auto"
        :src="ImagenBase64"
        lazy-src="https://picsum.photos/id/11/100/60">
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
    <v-col cols="12">
      <v-btn 
        dark
        color="btncolor"
        class="ma-2" 
        outlined 
        :href="ImagenBase64"
        download>
            Descargar
            <v-icon right size="25px">mdi-download</v-icon>
      </v-btn>
    </v-col>  
  </v-row>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
export default {
  name:"MostrarImagen",
  data() {
    return {
      ImagenBase64: '',
    }
  },
  mounted () {
    axiosServices.getConfiguration(`/getfile/foto/${this.$route.params.sFilename}`).then( Data => {
      this.ImagenBase64 = Data.aData
    }) 
    
  },
}
</script>

<style lang="scss" scoped>
.ClassImagen {
  height: 100vh;
  display:flex ;
  justify-content: center;
  align-content: center;
}

</style>